export default {
    props: {
        modifier: {
            type: [String, Array, Object],
            default: []
        }
    },
    computed: {
        modifiers() {
            const parsedModyfires =  this.parseClasses(this.modifier);
            const parsedLocalModifiers = this.parseClasses(this.localModifiers);
            return {...parsedLocalModifiers, ...parsedModyfires};
        },
        localModifiers() {
            return {};
        },
        hasModifiers() {
            return Object.keys(this.modifiers).length > 0;
        },
        blockName() {
            return this.$options.name.replace(/^[a-zA-Z]-/, '');
        },
        blockModifiersClasses() {
            let modifiersClasses = Object.keys(this.modifiers).reduce((acc, modifier) => {
                acc[`${this.blockName}--${modifier}`] = this.modifiers[modifier];
                return acc;
            }, {})
            modifiersClasses[`${this.blockName}`] = true;
            return modifiersClasses;
        }
    },
    methods: {
        hasModfier(modifier) {
            return Object.prototype.hasOwnProperty.call(this.modifiers, modifier);
        },
        parseClasses(classes) {
            if(!classes) 
                return {};
            else if(typeof classes === 'string') 
                return Object.fromEntries(classes.trim().split(' ').map(className => [className, true]));
            else if(classes instanceof Array) 
                return Object.fromEntries(classes.map(className => [className, true]));
            else if(classes instanceof Object) 
                return classes;
            else throw new Error('Invalid classes type. Must be string, array or object');

        },
        elementModifiersClasses(elementName, elementModyfires = [], restClasses = []) {
            const parsedElementsModyfires = this.parseClasses(elementModyfires);
            const allModifiers = {...parsedElementsModyfires, ...this.modifiers};
            const modifiersClasses = allModifiers ? Object.keys(allModifiers).reduce((acc, modifier) => ({ ...acc, [`${this.blockName}__${elementName}--${modifier}`]: allModifiers[modifier] }), {}) : {};            const parsedModyfiresClasses = this.parseClasses(modifiersClasses);
            const parsedRestClasses = this.parseClasses(restClasses);
            const parsedEelementClass= this.parseClasses(`${this.blockName}__${elementName}`);
            return {...parsedRestClasses, ...parsedEelementClass, ...parsedModyfiresClasses};
        }
    }
}
