<template>
  <div class="app">
    <s-sidebar class="app__sidebar"/>
    <s-main class="app__main"/>
  </div>
</template>

<script>
  import ApiService from '@/api/ApiService';
  import sSidebar from '@/components/sections/s-sidebar.vue';
  import sMain from '@/components/sections/s-main.vue';
  import Tracker from '@/lib/Tracker';


  export default {
    name: "app",
    data: () => ({
      api: new ApiService(process.env.VUE_APP_API_URL),
      tracker: {}
    }),
    mounted() {
      this.tracker = new Tracker(this.$gtag);
    },
    components: {
      sSidebar,
      sMain
    },
  }
</script>

<style lang="scss">
  .app {
      min-height: 100vh;
      margin: 0;
      padding: 0;
      background-color: $gray-color;

      @include brakepoint('xl') {
          display: flex;
          justify-content: center;
          align-items: stretch;

          &__main {
            flex: 1;
            max-width: 1300px;
        }
      }
      
    }
</style>
