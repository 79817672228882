<template>
    <section class="block s-about">
        <c-section-header class="s-about__header" icon="lni lni-user" bgtext="O mnie">O mnie</c-section-header>
        <p class="s-about__paragraph">
            Web Developer z ponad 6-letnim doświadczeniem zawodowym. Jeśli interesuje Cię, jak w praktyce wykorzystuję swoje umiejętności, zapraszam do dalszej lektury – opisuję w jaki sposób pracuję, od budowania stron opartych na WordPressie po tworzenie dedykowanych aplikacji webowych do zarządzania procesami technologicznymi oraz systemów typu hurtownia danych. Dla większej przejrzystości podzieliłem opisy mojej pracy na cztery główne obszary, które znajdziesz poniżej.        </p>
        <div class="s-about__container">
        <div class="s-about__column s-about__column--blocks" v-motion-left>
            <div class="s-about__blocks" >
                <c-skill-block class="s-about__block" :icon="require('@/assets/icons/frontend.svg')" color="blue" title="Frontend">
                    <p>Specjalizuję się w pracy z Vue.js, które wykorzystuję w większości moich projektów do tworzenia dynamicznych aplikacji, komunikujących się z backendowymi API. Moim podstawowym narzędziem jest Vue.js, jednak w zależności od projektu dostosowuję się do istniejących technologii i wymagań.</p>
                    <p>Zdarzają się sytuację, gdy mój projekt jest tylko modułem w większym systemie lub zostaje przeze mnie przejęty. W takich przypadkach pracuję z technologiami zastanymi w danym środowisku. Dzięki temu zdobyłem cenne doświadczenie w pracy z różnymi technologiami i frameworkami.</p>
                    <p>W swojej pracy kładę szczególny nacisk na czytelność i przejrzystość kodu. W CSS, gdy tylko mogę, korzystam ze metodologii BEM zarówno w aplikacjach klasycznych, jak i w Vue.js. W przypadku zastosowania czystego JavaScript dbam o strukturę kodu, pisząc go w sposób obiektowy i używając Webpacka jako bundlera.</p>
                </c-skill-block>
                <c-skill-block class="s-about__block" :icon="require('@/assets/icons/backend.svg')"  color="red" title="Backend">
                    <p>Po stronie backendu pracuję głównie z frameworkiem Laravel, natomiast jako silnika bazodanowego używam MySQL/MariaDB, a okazjonalnie także MsSQL. Podczas pracy z bazami danych staram się korzystać wyłącznie z Eloquenta, jednak zawsze sprawdzam generowane zapytania, ponieważ łatwo tu o nieświadome tworzenie skrajnie nieoptymalnych zapytań.</p>
                    <p>Moja praca z backendem nie ogranicza się jednak do Laravela. Istotną część mojej pracy stanowi jeden większy projekt (jak na skalę jednej osoby), który jest napisany w czystym PHP. Projekt ten przejąłem po innej firmie, więc dostosowałem się do istniejących rozwiązań. Standardem pracy w obu przypadkach jest oczywiście Composer, który zarządza zależnościami oraz dostarcza autoloader na potrzeby projektu.</p>
                </c-skill-block>
                <c-skill-block class="s-about__block" :icon="require('@/assets/icons/systems.svg')" color="orange" title="Systemy">
                    <p>W swojej karierze miałem okazję pracować z wieloma gotowymi systemami tworząc lub rozwijając dla nich moduły i motywy.</p>
                    <p>Obecnie najczęściej pracuję z PerfexCRM, CRM-em zawierającym między innymi system zgłoszeń, zarządzania relacjami z klientem, ofertowania i fakturowania. System ten umożliwia doinstalowanie zakupionych modułów, jednak nie zawsze zawierają one wszystkie funkcje potrzebne w biznesie klienta. Moją rolą jest rozwijanie istniejących modułów lub tworzenie ich od podstaw. Relacji z klientem, ofertowania i fakturowania.</p>
                    <p>Oprócz tego zdarzało mi się pracować z WordPressem oraz kilkukrotnie z PrestaShop dotyczyło to kwestii głównie front-endu gdzie realizowałem motywy potomne. Czasami była konieczność stworzenia prostej wtyczki jeśli API dla motywów nie pozwalało na dodanie niezbędnych funkcjonalności.</p>
                </c-skill-block>
                <c-skill-block class="s-about__block" :icon="require('@/assets/icons/tools.svg')" color="purple" title="Narzędzia">
                    <p>Narzędzia, z których korzystam w pracy, są dla mnie kwestiom drugorzędną, ponieważ najważniejsza jest wiedza. Niemniej jednak odpowiednie narzędzia w znaczący sposób wpływają na wydajność i komfort pracy. Dlatego chciałbym przedstawić narzędzia, z których aktualnie korzystam.</p>
                    <p>Moje podejście do doboru narzędzi opiera się na zasadzie, że czas którego wymagajś nie powinien przekraczać czasu który pozwalają zaoszczędzić.  Jako środowisko deweloperskie używam VS Code, które jest bezpłatne i po zainstalowaniu odpowiednich rozszerzeń w pełni spełnia moje potrzeby. Do pracy z bazami danych korzystam z DBeaver o ile mogę połączyć się bezpośrednio po SQL i uniknąć korzystania z klienta webowego.</p>
                    <p>Moja główna przeglądarka deweloperska to Chrome, która oferuje solidne narzędzia deweloperskie, liczne funkcje oraz możliwość debugowania stron na fizycznych urządzeniach z Androidem, z czego często korzystam. Uzupełniam ją też o kilka wtyczek  między innymi Vue.js devtools i xDebug helper. </p>
                    <p>Podczas pracy nad backendem oraz testowania API, korzystam z Postmana, który pozwala na szybkie generowanie zapytań do serwera w różnych formatach i z różnymi nagłówkami. Postman oferuje również możliwość sprawdzania odpowiedzi przy pomocy skryptów w JavaScript, co jest bardzo pomocne, szczególnie gdy odpowiedzią jest duży JSON, a ja muszę każdorazowo sprawdzić strukturę i liczbę danych.</p>
                </c-skill-block>
            </div>
        </div>
            <div class="s-about__column s-about__column--plans" v-motion-right>
               <h3 class="subheader s-about__subheader">Sztuczna inteligencja</h3>
                <p class="s-about__paragraph">Popularność i dynamiczny rozwój dużych modeli językowych na naszych oczach zmienia sposób pracy programistów, w tym również mój. Wychodzę z założenia że potencjał AI należy wykorzystać w celu zwiększenia wydajności pracy jednak bez utraty jej jakości. Narzędzie które stosuje musi więc istotnie wpływać na wydajność – minimalizując przy tym ryzyka związane z jego wykorzystaniem.</p>
                <p class="s-about__paragraph">Obecnie korzystam z Github Copilot w wersji komercyjnej, licencja ta zapewnia że kod na którym pracuje nie będzie wykorzystywany do trenowania modelu ogólnego. Tym co dodatkowo przemawia za korzystaniem z usługi GitHuba jest to, że korzystam z niego jako repozytorium więc i tak ma już dostęp do mojego kodu. W kontekście wydajności kluczową zaletą Copilota jest również jego integracja z VS Code, co znacząco przyspiesza pracę – nie muszę kopiować i wklejać kodu, a otrzymuję jakościowe podpowiedzi w czasie rzeczywistym oraz mam wbudowany chat który widzi otwarty projekt.</p>
            </div>
        </div>
        <c-call-to-action class="s-about__call-to-action" :class="elementModifiersClasses('call-to-action', 'full')"/>

    </section>
</template>

<script>
    import cSectionHeader from '@/components/c-section-header.vue';
    import cSkillBlock from '@/components/c-skill-block.vue';
    import cCallToAction from '@/components/c-call-to-action.vue';
    import personalData from '@/data/personal.json';
    import modifiersMixin from '@/mixins/modifiers';

    export default {
        name: "s-about",
        extends: modifiersMixin,
        data: () => {
            return {
               personalData
            }
        },
        components: {
            cSectionHeader,
            cSkillBlock,
            cCallToAction
        }
    }
</script>

<style lang="scss">
    .s-about {

        &__container {
            margin-top: 4rem;

            @include brakepoint('md') {
                display: flex;

            }
        }

        &__column {
            flex: 1;

            &--plans {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding-top: 2rem;
            }
            
            @include brakepoint('sm') {
                &--plans { 
                    padding-top: 0;
                    padding-left: 2rem;
                }
            }
        }

        &__blocks {
            margin: auto;
            max-width: 450px;
            gap: 2rem;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 3rem;

            @include brakepoint('xs') {
                display: grid;
            }
        }

        &__block {
            margin-bottom: 2rem;
            @include brakepoint('xs') {
                margin-bottom: 0;
            }
        }
        
        &__subheader {
            margin-bottom: 0;
        }

        &__call-to-action {
            margin-top: auto;

            &--tiny {
                @include brakepoint('sm') {
                    display: none;
                }

                @include brakepoint('md') {
                    display: block;
                }

                @include brakepoint('xl') {
                    display: none;
                }

                @include brakepoint('xxl') {
                    display: block;
                }
            }

            &--full {
                --display: flex;
                margin-top: 2rem;
                display: none;

                @include brakepoint('sm') {
                    display: var(--display);
                }

                @include brakepoint('md') {
                    display: none;
                }

                @include brakepoint('xl') {
                    display: var(--display);
                }

                @include brakepoint('xxl') {
                    display: none;
                }
            }
  
        }
    }
</style>