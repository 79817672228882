export default {
    methods: {
        linkTo(event, name, location, url) {
            event.preventDefault();
            event.stopPropagation();
            window.open(url, '_blank');
            this.$gtag.event('link', {
                name: name,
                location: location
            });
        }
    }
}