<template>
    <div class="bg-primary" :class="blockModifiersClasses">
        <div class="lni lni-thought call-to-action__icon"></div>
        <div class="call-to-action__text" :class="elementModifiersClasses('text')">
            <div class="call-to-action__title">Zainteresowany współpracą?</div>
            <div class="call-to-action__subtitle" :class="elementModifiersClasses('subtitle')">Skontaktuj się ze mną!</div>
        </div>
        <i-button class="call-to-action__button" :class="elementModifiersClasses('button')" modifier="white" icon="lni lni-envelope" @click="linkTo($event, 'email', 'call to action', 'mailto:kontakt@mkawczynski.pl')">Napisz do mnie</i-button>
    </div>
</template>

<script>

    import modifiers from '@/mixins/modifiers';
    import iButton from '@/components/interface/i-button.vue';
    import linkTo from '@/mixins/linkTo'; 
    export default {
        name: "c-call-to-action",
        mixins: [modifiers, linkTo],
        components: {
            iButton
        }
    }
</script>

<style lang="scss">
    .call-to-action {
        padding: 2rem;
        
        @include brakepoint('sm') {
            display: flex;
        }

        @include brakepoint('md') {
            padding: 3rem;
        }

        &__icon {
            display: none;
            width: fit-content;
            font-size: 5rem;

            @include brakepoint('sm') {
                display: block;
            }
        }

        &__text {
            font-size: 2rem;
            margin-bottom: 2rem;
            font-weight: bold;
            text-align: center;

            @include brakepoint('sm') {
                margin-bottom: 0;
                margin-left: 2rem;
                text-align: left;

            }
        }

        &__subtitle {
            display: none;

            @include brakepoint('md') {
                display: block;
            }
        }

        &__button {
            width: 100%;
            @include brakepoint('sm') {
                width: auto;
                margin-left: auto;
            }
        }
    }
</style>