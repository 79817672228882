export default class {
    constructor(defaultData) {
        this.defaultData = defaultData;
        this.expiryTime = 10000;
        this.interval = setInterval(() => this.get(), this.expireTime * 1.1); 
    }
    init() {
        this.store(this.defaultData);
        return this.defaultData;
    }

    store(data = this.defaultData) {
        data.expire = Date.now() + this.expiryTime;
        this.data = data;
        localStorage.setItem('store', JSON.stringify(data));
    }
    get() {

        let data = JSON.parse(localStorage.getItem('store'));
        
        if(data === null || data.expire < Date.now())
            data = this.init();
        delete data.expire;
        return data;
    }
}