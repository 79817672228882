<template>
    <div class="block block--inner work-history" @click="changeBlockName()" ref="block">
        <div class="work-history__line-column work-history__line-column--left"></div>
        <div element="main-column" ref="mainColumn" @mouseover="hover = true" @mouseleave="hover = false">
            <div class="work-history__element" :class="'work-history__element--' + getItemColor(index)" v-for="(job, index) in jobs" :key="index" ref="workHistoryElementsRefs" @mouseover="hoverElement = index">
                <div class="work-history__rounded-dot" :class="'work-history__rounded-dot--' + getItemColor(index)"></div>
                <h3 class="subheader subheader--small work-history__title">{{ job.title }}</h3>
                <p class="work-history__date" :class="'work-history__date--' + getItemColor(index)">{{ job.date.start }} - {{ job.date.end }}</p>
                <p class="work-history__description">{{ job.description }}</p>
                <ul class="work-history__duties" v-if="job.duties">
                    <li class="work-history__dutie-item"  v-for="(duty, index) in job.duties" :key="index">{{ duty }}</li>
                </ul>
                <a class="work-history__link" :class="'work-history__link--' + getItemColor(index)" @click="linkTo($event, job.link, 'history', job.link)"  :href="job.link" target="_blank">{{ job.link }}</a>
            </div>
        </div>
        <div class="work-history__line-column work-history__line-column--right">
            <div class="work-history__inner-line" :class="'work-history__inner-line--' + getItemColor(activeElement)" :style="{height: lineDotPosition}">
                <div class="work-history__line-dot"  :class="'work-history__line-dot--' + getItemColor(activeElement)"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import linkToMixin from '@/mixins/linkTo';

    export default {
        name: "u-work-history",
        mixins: [linkToMixin],
        data: () => {
            return {
                number: 1,
                workHistoryElementsSizes: [],
                closestToCenter: 0,
                overflow: false,
                hover: false,
                hoverElement: 0,
                events: {},
            }
        },
        mounted() {
            this.events.resizeObserver = new ResizeObserver(this.updateWorkHistoryElementsSizes);
            this.events.resizeObserver.observe(this.$refs.mainColumn);
            this.events.scrool = window.addEventListener('scroll', this.updateClosestToCenter);
        },
        beforeUnmount() {
            window.removeEventListener('scroll', this.events.scrool);
            this.events.resizeObserver.unobserve(this.$refs.mainColumn);
        },
        computed: {
            jobs() {
                return this.$store.getters.jobs;
            },
            activeElement() {
                this.updateClosestToCenter();
                return this.hover ? this.hoverElement : this.closestToCenter;
            },
            lineDotPosition() {
                let position = 0;
                if(this.workHistoryElementsSizes !== undefined) {
                    if(this.overflow)
                        position = "100%";
                    else {
                        for(let i = 0; i < this.activeElement && i < this.workHistoryElementsSizes.length; i++)
                            position += this.workHistoryElementsSizes[i].height + this.workHistoryElementsSizes[i].margin;

                        if(this.workHistoryElementsSizes.length > 0 && this.activeElement != null)
                            position += this.workHistoryElementsSizes[this.activeElement].height * 0.8;
                        position += 'px';
                    }
                }
                return position;
            }
    },
    methods: {
        changeBlockName() {
            this.blockName = `work-history-#${this.number++}`;
        },
        updateWorkHistoryElementsSizes() {
            this.workHistoryElementsSizes = [];
            if(this.$refs.workHistoryElementsRefs !== undefined)
                this.$refs.workHistoryElementsRefs.forEach(element => {
                    const style = window.getComputedStyle(element);
                    const marginTop = parseFloat(style.marginTop);
                    const marginBottom = parseFloat(style.marginBottom);
                    this.workHistoryElementsSizes.push({
                        height: Math.round(element.clientHeight),
                        margin: Math.round(marginTop + marginBottom)
                    });
                });
        },
        updateClosestToCenter() {
            let closestElement = null;
            let closestDistance = Infinity;
            let overflow = false;

            if(this.$refs.workHistoryElementsRefs !== undefined) {
                const centerY = window.innerHeight / 2;

                this.$refs.workHistoryElementsRefs.forEach((element, index) => {
                    const rect = element.getBoundingClientRect();
                    const elementCenterY = rect.top + rect.height / 2;
                    const distance = centerY - elementCenterY;
                    const distanceAbs = Math.abs(distance);
                    if(distanceAbs < closestDistance) {
                        closestDistance = distanceAbs;
                        closestElement = index;
                        if(index === this.$refs.workHistoryElementsRefs.length - 1 && distance > window.innerHeight / 6 && !this.hover)
                            overflow = true;
                    }
                });
            }
            this.overflow = overflow;
            this.closestToCenter = closestElement;
        }
    }
    
}
</script>

<style lang="scss">
    .work-history {
        --main-column-margin: 30px;
        --line-column-width: 2px;

        @include brakepoint('md') {
            --main-column-margin: 40px;
        }

        display: flex;
        justify-content: stretch;
        align-items: stretch;
        
        &__main-column {
            flex: 1;
            margin: 0 var(--main-column-margin);
        }

        &__line-column {
            --display: flex;
            --column-margin: calc(var(--main-column-margin) - 10px);

            display: none;
            justify-content: center;
            width: var(--line-column-width);
            background-color: $gray-dark-color;

            &--left {
                margin-right: var(--column-margin);

                @include brakepoint('xs') {
                    display: var(--display);
                }
            }

            &--right {
                margin-left: var(--column-margin);

                @include brakepoint('sm') {
                    display: var(--display);
                }
            }
        }

        &__inner-line {
            position: relative;
            display: block;
            width: 100%;
            height: 50%;
            background-color: $blue-color;
            transition: height $animation, background-color $animation;

            @include colors-modyfaiers(background-color);
        }

        &__line-dot {
            $size: 10px;
            position: absolute;
            left: -$size / 2;
            bottom: 1px;
            width: $size;
            height: $size;
            border-radius: 50%;
            background-color: $blue-color;
            transition: background-color $animation;

            @include colors-modyfaiers(background-color);

        }

        &__element {
            position: relative;
            margin-top: 30px;
            margin-bottom: 30px;

            &:not(:last-child) {
                @include brakepoint('md') {
                    margin-bottom: 60px;
                }
            }
        }

        &__rounded-dot {
            --size: 20px;

            display: none;
            position: absolute;
            left: calc(11px - var(--main-column-margin) - var(--line-column-width) / 2 - var(--size) / 2);
            width: var(--size);
            height: var(--size);
            border-radius: 50%;
            background: radial-gradient(var(--color) 0% 25%, transparent 30% 55%, var(--color) 60% 100%);

            @include colors-modyfaiers(--color);

            @include brakepoint('xs') {
                display: unset;
            }

            @include brakepoint('md') {
                top: -4px;
            }
        }

        &__title {
            margin-bottom: 1rem;
        }

        &__date {
            margin-top: 0;
            @include colors-modyfaiers(color);
        }

        &__duties {
            font-size: 1.3rem;
            padding-left: 2rem;
            color: $font-gray-color;
            
            @include brakepoint('sm') {
                padding-left: 4rem;
            }
        }

        &__dutie-item {
            margin-bottom: .5rem;
        }

        &__link {
            font-style: italic;
            font-size: 1.3rem;
            text-decoration: none;

            @include colors-modyfaiers-pseudo(list.join('&', $link-pseudos), color);
        }
    }
</style>