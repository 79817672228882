<template>
    <div class="block name">
        <h1 class="name__heading">Mikołaj Kawczyński</h1>
        <div class="name__subtitle">
            <span class="name__bold">Fullstack Web Developer</span>
            z Poznania
        </div>
    </div>
</template>

<script>
    export default {
        name: "u-name"
    }
</script>

<style lang="scss">
    .name {
        margin: 0;
        padding: 0;
        &__heading {
            margin: 0;
            margin-bottom: 1rem;
            padding: 0;
            text-align: center;
            font-family: "Merienda";
            font-size: 2rem;
            font-weight: normal;

            @include brakepoint("xs") {
                font-size: 2.4rem;
            }
        }

        &__subtitle {
            text-align: center;
            font-size: 1.1rem;

            @include brakepoint("xs") {
                font-size: 1.3rem;
            }
        }

        &__bold {
            color: $primary-color;
        }
    }

</style>