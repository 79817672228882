<template>
    <main class="s-main" @click="test">
        <i-main-menu class="s-main__menu" :activeSection="activeSection" :topMargin="this.topMargin"/>
        <s-slider class="s-main__section" id="naglowek"/>
        <s-about class="s-main__section" id="o-mnie"/>
        <s-skills class="s-main__section" id="umiejetnosci"/>
        <s-experience class="s-main__section" id="doswiadczenie"/>
    </main>
</template>

<script>
    import iMainMenu from '@/components/interface/i-main-menu.vue';
    import sSlider from '@/components/sections/s-slider.vue';
    import sAbout from '@/components/sections/s-about.vue';
    import sSkills from '@/components/sections/s-skills.vue';
    import sExperience from '@/components/sections/s-experience.vue';
    import brakepointsMixin from '@/mixins/breakpoints.js';

    export default {
        name: "s-main",
        mixins: [brakepointsMixin],
        data: () => ({
           sections: [],
           observer: null
        }),
        created() {
          
        },
        mounted() {
            this.createObserver(this.breakpoints.lg);
        },
        beforeUnmount() {
            if(this.observer)
                this.observer.disconnect();
        },
        computed: {
            activeSection() {
                if(this.sections.length === 0)
                    return null;
                return this.sections.reduce((prev, current) => (prev.ratio > current.ratio) ? prev : current).name;
            },
            topMargin() {
                return this.breakpoints.lg ? 100 : 0;
            }
        },
        methods: {
            handleIntersection(entries) {
                entries.forEach(entry => {
                    const sectionIndex = this.sections.findIndex(section => section.name === entry.target.id);
                    const value = {
                            name: entry.target.id,
                            ratio: entry.intersectionRatio
                    };
                    if(sectionIndex === -1)
                        this.sections.push(value);
                    else
                        this.sections[sectionIndex] = value;
                     
                });
            },
            test() {
                console.log(getComputedStyle(this.$el).getPropertyValue('--top-margin'));
            },
            createObserver() {
                if(this.observer)
                    this.observer.disconnect();
                const rootBottomMargin = `-${window.innerHeight / 3}px`;
                this.observer =  new IntersectionObserver(this.handleIntersection, {
                    rootMargin: `-${this.topMargin}px 0px ${rootBottomMargin} 0px`,
                    threshold: 0.01
                });
                this.$el.querySelectorAll('section').forEach(section => {
                        this.observer.observe(section);
                });
            }
        },
        watch: {
            activeSection(before, after) {
                if(after != null || before != null)
                    this.$gtag.event('section-change', { section: after});
            }
        },
        components: {
            iMainMenu,
            sSlider,
            sAbout,
            sSkills,
            sExperience,
        },
    }
</script>

<style lang="scss">
    .s-main{
        &:first-of-type {
            margin-top: 5px;
        }
    }

</style>