<template>
    <div class="certificate">
        <h3 class="subheader subheader--small certificate__title"> {{ title }}</h3>
        <p class="certificate__decription">Bezpieczeństwo realizowanych projektów oraz pracy jest dla mnie niezwykle istotną kwestią. Obejmuje ono wszystkie aspekty — od tworzonego kodu, przez dobór odpowiednich zewnętrznych komponentów, aż po stosowane narzędzia i metody dostępu.</p>
        <p class="certificate__decription">Swoją wiedzę w tym zakresie poszerzyłem dzięki kursowi Niebezpiecznika, podczas którego poznałem praktyczną stronę współczesnych ataków oraz skuteczne metody zabezpieczania się przed nimi. Ta świadomość pozwala mi lepiej oceniać pisany kod oraz rozumieć wagę nawet drobnych zmian w kontekście bezpieczeństwa.</p>
        <l-img-lightbox class="certificate__image" :src="image" :alt="title" />      
    </div>
</template>

<script>
    import lImgLightbox from '@/components/lightboxes/l-img-lightbox.vue';
    import certificateNiebezpiecznik from '@/assets/images/certyfikat-niebezpiecznik.jpg';

    export default {
        name: "u-certificate",
        data () {
            return {
                title: 'Certyfikat Niebezpiecznik',
                image: certificateNiebezpiecznik,
            }
        },
        components: {
            lImgLightbox
        }
    }
</script>

<style lang="scss">
    .certificate {

        &__image {
            margin: auto;
            max-width: 300px;
        }
    }
</style>