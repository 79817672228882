<template>
    <section class="block s-experience">
        <c-section-header class="s-experience__header" icon="lni lni-briefcase" bgtext="Doświadczenie">Doświadcznie zawodowe</c-section-header>
        <div class="s-experience__columns">
            <u-work-history class="s-experience__column s-experience__column--work" v-motion-left></u-work-history>
            <u-certificate class="block block--inner s-experience__column s-experience__column--certificate" v-motion-right></u-certificate>
        </div>
    </section>
</template>

<script>
    import cSectionHeader from '@/components/c-section-header.vue';
    import uWorkHistory from '@/components/unique/u-work-history.vue';
    import uCertificate from '@/components/unique/u-certificate.vue';

    export default {
        name: "s-experience",
        components: {
            cSectionHeader,
            uWorkHistory,
            uCertificate
        }
    }
</script>

<style lang="scss">
    .s-experience {

        &__header {
            margin-bottom: 5rem;
        }

        &__columns {
            justify-content: space-between;

            @include brakepoint('xxl') {
                display: flex;
            }
        }

        &__column {
            margin: 0;

            &--work {
                flex: 2;
            }

            &--certificate {
                flex: 1;
                margin-top: 30px;

                @include brakepoint('xxl') {
                    margin-top: 0;
                    margin-left: 15px;
                }
            }
        }
    }

</style>