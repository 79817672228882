<template>
    <nav class="block" :class="blockModifiersClasses">
        <i class="lni lni-close sidebar__collapse" @click="active = false"></i>
        <i-expand class="sidebar__expand" @click="toogleSidebar()" :active="active"/>
        <div class="sidebar__content">
            <img class="sidebar__portrait" src="@/assets/images/portretowe.jpg"/>
            <div class="sidebar__info">
                <u-name class="sidebar__name" />
                <c-data-table class="sidebar__data-table" :items="personalData"/>
            </div>
        </div>
        <teleport to="body">
            <div class="sidebar__overrlay" v-if="active"></div>
        </teleport>
    </nav>

</template>

<script>
    import modifiers from '@/mixins/modifiers';
    import iExpand from '@/components/interface/i-expand.vue';
    import uName from '@/components/unique/u-name.vue';
    import cDataTable from '@/components/c-data-table.vue';

    export default {
        name: "s-sidebar",
        mixins: [modifiers],
        components: {
            uName,
            cDataTable,
            iExpand
        },
        data() {
            return {
                active: false
            }
        },
        computed: {
            localModifiers() {
                return {
                    'active': this.active
                };
            },
            personalData() {
                return this.$store.getters.personal;
            }
        },
        watch: {
            active(before, after) {
                document.body.style.overflow = this.active ? 'hidden' : null;
                this.$gtag.event('nav-use', {location: 'sidebar', action: after ? 'close' : 'open'});
            }
        },
        methods: {
            toogleSidebar() {
                this.active = !this.active;
            }
        }
    }

</script>

<style lang="scss">
    .sidebar {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 6;
        box-sizing: border-box;
        width: 100vw;
        height: 100vh;
        overflow: visible;
        margin: 0;
        padding: 0;
        transform: translateX(-100%);

        @include animation(transform);

        @include brakepoint('sm', 'port') {
            width: 350px;
        }

        @include brakepoint('md') {
            width: 350px;
        }

        @include brakepoint('xl') {
            position: sticky;
            min-height: 100vh;
            margin-top: 3rem;
            margin-left: 3rem;
            transform: none;
        }

        &--active {
            transform: none;
            overflow-y: auto;
            @include brakepoint('xl') {
                overflow-y: unset;
                transform: unset;
            }
        }

        &__content {
            @include brakepoint('sm', 'land') {
                height: 100vh;
                width: 100vw;
                display: flex;
            }
        }

        &__portrait {
            width: 100%;
            max-height: 500px;
            bottom: 0;
            object-fit: cover;
            object-position: top;
            background-color: var(--gray-color);

            @include brakepoint('sm') {
                aspect-ratio: 1 / 1;
                height: unset;
                max-height: unset;

                @include rule('land') {
                    width: 50%;
                    height: 100vh;
                }
            }
            
        }

        &__info {
            width: 100%;
        }
        
        &__collapse {
            position: absolute;
            top: 2rem;
            right: -1rem;
            font-size: 3rem;
            color: var(--white-color);
            cursor: pointer;
            transform: translateX(-100%);
            @include animation(color);

          
            @include brakepoint('sm') {
                right: 0;

                @include rule('land') {
                    right: -2rem;
                    top: 1rem;
                    color: var(--font-color);
                }

            }

            @include brakepoint('xl') {
                display: none;
            }

            &:hover,
            &:active,
            &:focus {
                color: var(--primary-color);
            }
        }

        &__expand {
            position: absolute;
            right: 0;
            z-index: 10;
            transform: translateX(100%);

            @include brakepoint('xl') {
                display: none;
            }
        }

        &__overrlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: #000;
            opacity: 0.5;
            z-index: 5;

            @include brakepoint('xl') {
                display: none;
            }
        }

        &__name {
            padding: 1rem;
            padding-bottom: 2rem;

         
            @include brakepoint('sm') {
                padding: 2rem;
                padding-bottom: 3rem;
            }

            
        }

        &__data-table {
            margin: auto;
            padding: 0 2rem;

            @include brakepoint('sm') {
                padding: 0;
                margin: 2rem;
            }
        }
    }
</style>