import axios from "axios";

export default class {
    constructor(baseURL) {
        this.axios = axios.create({
            baseURL: baseURL,
            timeout: 1000
        });
    }

    async auth(token) {
        return this.axios.post('/auth', { 'token': token })
                .then(response => response.json())
                .then(response =>  response.success);
    }

    ready() {
        return  Boolean(this.axios.baseURL);
    }
}