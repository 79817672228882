<template>
    <figure class="skill-chart" :class="'skill-chart--' + type" :style="{
        'font-size': skillChartSize * 0.15 + 'px',
    }">
        <div class="skill-chart__text"><slot></slot></div>
        <div class="skill-chart__progress" ref="skillChart" :style="{background: background, transform: `rotate(${rotate}deg)`}"></div>
    </figure>
</template>

<script>
    import { ref } from 'vue';
    import { useElementSize } from '@vueuse/core';

    export default {
        name: "g-skill-chart",
        props: {
            backgrounColor: {
                type: String,
                default: '#fff'
            },
            type: {
                type: String,
                default: 'blue'
            },
            value: {
                type: Number,
                required: true
            }
        },
        data: () => {
            return {
                gap: 3,
                rotate: 0
            }
        },
        setup() {
            const skillChart = ref(null);
            return {
                skillChart,
                skillChartSize: useElementSize(skillChart).width
            }
        },
        computed: {
            background() {
                const calcGap = this.value > (100 - this.gap) ? 100 - this.value : this.gap;
                let gradients =  `
                    radial-gradient(closest-side, #fff 79%, transparent 80% 100%), 
                    conic-gradient(var(--active-color) ${this.value}%, transparent ${this.value}% ${this.value + calcGap}%, var(--inactive-color) ${this.value + calcGap}% ${100 - calcGap}%, transparent ${100 - calcGap}% 100%)
                `;
                return gradients.replaceAll(/(\r\n|\n|\r|\t)/gm, "");
            },
        }
    }
</script>

<style lang="scss">
    .skill-chart {
        --inactive-color: #{$gray-dark-color};
        position: relative;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        @include colors-modyfaiers('--active-color');

        &__progress {
            display: grid;
            aspect-ratio: 1;
            z-index: 0;
            top: 0;
            left: 0;
            border-radius: 50%;
            max-width: 100%;
            width: 100%;
        }

        &__text {
            width: 100%;
            overflow: hidden;
            margin-bottom: 0.5rem;

            @include brakepoint('sm') {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                height: 100%;
                width: 100%;
                margin-bottom: 0;
                z-index: 1;
                top: 0;
                bottom: 0;
                font-size: 0.65em;
            }
        }
    }
</style>