<template>
    <div class="skill-bar" :class="'skill-bar--' + color">
        <div class="skill-bar__name"><slot></slot></div>
        <div class="skill-bar__bar-container">
            <div class="skill-bar__value" :class="'skill-bar__value--' + color" :style="{ paddingRight: 100 - progress + '%' }">{{ progress }}%</div>
            <div class="skill-bar__bar">
                <div class="skill-bar__progress" :class="'skill-bar__progress--' + color" :style="{ width: progress + '%' }"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "g-skill-bar",
        props: {
            progress: {
                type: Number,
                required: true
            },
            color: {
                type: String,
                default: 'blue'
            }
        }
    }
</script>

<style lang="scss">
    .skill-bar {
        @include brakepoint('xs') {
            display: flex;
        }

        &__name {
            margin-bottom: 1.7rem;
            font-size: 1.5rem;
            color: $font-gray-color;

            @include brakepoint('xs') {
                width: 30%;
                min-width: 10rem;
                margin-bottom: 0;
            }
        }

        &__bar-container {
            position: relative;            
            display: flex;
            align-items: center;
            width: 80%;
        }

        &__value {
            position: absolute;
            top: -1.5rem;
            box-sizing: border-box;
            width: 100%;
            font-size: 1.2rem;
            text-align: right;
            transition: padding-right $animation;

            @include colors-modyfaiers('color');
        }

        &__bar {
            width: 100%;
            height: 4px;
            background-color: $gray-dark-color;
            overflow: hidden;
        }

        &__progress {
            height: 100%;
            transition: width $animation;

            @include colors-modyfaiers('background-color');
        }
    }
</style>