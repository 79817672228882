<template>
    <button :disabled="disabled" :class="blockModifiersClasses">
        <span v-if="icon" :class="[icon, 'button__icon' ,'button__icon--' + type]"></span>
        <span class="button__text" :class="'button_text--' + type"><slot></slot></span>
    </button>
</template>

<script>
    import modifiers from '@/mixins/modifiers';

    export default {
        name: "i-button",
        mixins: [modifiers],
        props: {
            icon: String,
            type: {
                type: String,
                default: 'normal'
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            localModifiers() {
                return {
                    disabled: this.disabled
                }
            }
        }
    }
</script>

<style lang="scss">
    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        outline: 0;
        border: 0;
        vertical-align: center;
        cursor: pointer;
        font-size: 1.5rem;
        color: $white-color;
        background-color: $primary-color;

        @include brakepoint('xs') {
            padding: 2rem 4rem;
        }

        &--disabled {
            opacity: .7;
            cursor: not-allowed;
        }

        &--white {
            color: $font-color;
            background-color: $white-color;
        }

        &--small {
            padding: 1rem 2rem;
            font-size: 1rem;
        }

        &__text {
            white-space: nowrap;
        }

        &__icon {
            margin-right: 1rem;
            font-size: 2rem;
        }
    }
</style>