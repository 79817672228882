import { useBreakpoints } from '@vueuse/core'

export default {
    data: {
        breakpoints: useBreakpoints({
            xs: 320,
            sm: 576, 
            md: 768,
            lg: 992,
            xl: 1200,
            xxl: 1450
        })
    }
}