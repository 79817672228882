<template>
    <div class="data-table">
        <h3 class="subheader data-table__header" > {{ title }}</h3>
        <ul class="data-table__list">
            <li v-for="item in items" :key="item.label" class="data-table__item">
                <div class="data-table__label">{{ item.name }}</div>
                <div class="data-table__separator">:</div>
                <a class="data-table__value data-table__value--link" v-if="item.link" :href="item.link" @click="linkTo($event, item.name, 'data-table', item.link)">{{ item.value }}</a>
                <div class="data-table__value" v-else>{{ item.value }}</div>
            </li>
        </ul>
    </div>
    
</template>

<script>
    import linkToMixin from '@/mixins/linkTo';

    export default {
        name: "c-data-table",
        mixins: [linkToMixin],
        props: {
            title: {
                type: String,
                required: false
            },
            items: {
                type: Array,
                required: true
            }
        }
    }
</script>

<stle lang="scss">
    .data-table {
        max-width: 400px;
        list-style: none;

        &__list {
            @include no-list;
        }

        &__item {
            margin-bottom: 1rem;
            font-size: 1.4rem;

            @include brakepoint('xs') {
                display: flex;
            }
        }

        &__label {
            display: inline-block;
            color: $font-gray-color;

            
            @include brakepoint('xs') {
                display: block;
                width: 25%;
            }

            @include brakepoint('sm') {
                width: 30%;
            }
        }

        &__separator {
            display: inline-block;
            flex: 1;
            color: $font-gray-color;

            @include brakepoint('xs') {
                display: block;
            }
        }

        &__value {
            display: block;
            width: auto;
            font-style: italic;
        }
    }
</stle>