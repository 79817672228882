<template>
    <div class="skills-stats">
        <i-hash-menu class="skills-stats__menu" :items="categories" v-model="active"/>
        <div class="block block--inner skills-stats__block" v-motion-up>
            <h2 class="subheader skills-stats__subheader">Praktyczne umiejętności</h2>
            <div class="skills-stats__bars">
                <g-skill-bar v-for="(item, index) in activeSkills" :key="item.name" :progress="item.value" :color="getItemColor(index)" @mouseover="$gtag.event('skill-hover', {name: item.name})"> {{ item.name }}</g-skill-bar>
            </div>
        </div>
    </div>
</template>

<script>
    import iHashMenu from '@/components/interface/i-hash-menu.vue';
    import gSkillBar from '@/components/graphs/g-skill-bar.vue';

    export default {
        name: "u-skills-stats",
        data: () => {
            return {
                active: 'frontend',
            }
        },
        computed: {
            skills() {
                return this.$store.state.skills;
            },
            categories() {
                return Object.keys(this.skills);
            },
            activeSkills() {
                return this.skills[this.active];
            }
        },
        components: {
            iHashMenu,
            gSkillBar
        }
    }
</script>

<style lang="scss">
    .skills-stats {

        &__subheader {
            margin-bottom: 2rem;
        }

        &__bars {
            display: grid;
            margin-bottom: 2rem;
            gap: 2rem;
            grid-template-columns: 1fr;;

            @include brakepoint('sm') {
                gap: 3rem;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
</style>