import Cookies from "js-cookie";
export default class Tracker {
    constructor(VueGtag) {
        this.$gtag = VueGtag;
        window.setDebug = this.setDebug.bind(this);
        document.body.addEventListener('copy', (event) => this.$gtag.event('selection', {type: 'copy', content: event.target.textContent}));
        document.body.addEventListener('cut', (event) => this.$gtag.event('selection', {type: 'cut', content: event.target.textContent}));
        document.addEventListener('selectionchange', this.selectionchange.bind(this));
        window.addEventListener('error', (event) => this.$gtag.event('error', {message: event.message, filename: event.filename, lineno: event.lineno}));
        this.tracklinkCheck();
    }
    
    setDebug(debug) {
        if(debug === this.debug)
            return false;
        Object.keys(Cookies.get()).forEach(key => Cookies.remove(key));
        if(debug)
            Cookies.set('debug', 'true', { expires: 365 });

        window.location.reload();
        return true;
    }

    selectionchange() {
        const selection = window.getSelection();
        if(selection.toString().length > 3)
            this.$gtag.event('selection', {type: 'select', content: selection.toString()});
    }

    tracklinkCheck() {
        const url = window.location.href;
        const match = url.match(/\/t\/([a-z]+)\/([a-z]+)\/([a-z]+)/);
        if (match) {
          this.$gtag.set({
            campaign_id: match[1],
            campaign_source: match[2],
            campaign_value: match[3]
          });
          history.replaceState(null, '', '/');
        }    
    }

}