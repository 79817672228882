<template>
    <div class="profile-image" :class="blockModifiersClasses" @mouseover="this.$gtag.event('easter-egg', { name: 'shutterstock' })">
           <div class="profile-image__icon-bulb" v-if="!hasModfier('small')" ref="bulb" @click="linkTo($event, 'linkedin', 'profile image', 'https://www.linkedin.com/in/mikolaj-kawczynski/')">
               <span class="lni lni-users profile-image__icon" :style="{'font-size': this.bulbSize * 0.5 + 'px'}"></span>
           </div>
           <div :class="elementModifiersClasses('cropp')">
                <img class="profile-image__image" src="@/assets/images/profilowe.jpg">
                <div class="profile-image__watermark" v-if="!hasModfier('small')">
                    <div class="profile-image__watermark-slide">
                        <img class="profile-image__watermark-image" src="@/assets/images/watermark.png">
                    </div>
                    <div class="profile-image__watermark-slide">
                        Orginalny <br />
                        i <br />
                        niepowtarzalny
                    </div>
                </div>
           </div>
    </div>
</template>

<script>
   import { useElementSize } from '@vueuse/core';
   import { ref } from 'vue'
   import modifiersMixin from '@/mixins/modifiers';
   import linkToMixin from '@/mixins/linkTo';

   export default {
       name: "c-profile-image",
       mixins: [modifiersMixin, linkToMixin],
        setup() {
            const bulb = ref(null);
            const bulbSize =  useElementSize(bulb).width;
            return {
                bulb,
                bulbSize
            }
        }
   }
</script>

<style lang="scss">
   .profile-image {
        display: grid;
        position: relative;
        width: 100%;
        aspect-ratio: 1 / 1;
           
        
        &__icon-bulb {
            
            --size: 25%;
            --position: calc(-0.4 * var(--size) + 13%);
            position: absolute;
            z-index: 2;
            top: var(--position);
            left: var(--position);
            display: grid;
            aspect-ratio: 1 / 1;
            justify-content: center;
            align-items: center;
            width: var(--size);
            overflow: hidden;
            border-radius: 100%;
            color: $white-color;
            background-color: $accient-color;
            font-size: 5vw;

        }
        
        &__cropp {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            overflow: hidden;
        }

        &__image {
            width: 100%;
            height: 100%;
            background-color: $gray-dark-color;
        }

        &__watermark {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: transform $animation; 

             &:hover {
                transform: translateY(-100%);
            }
        }

        &__watermark-slide {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 3rem;
            font-weight: 500;
            font-family: "Merienda";
            font-weight: bold;
            text-align: center;
            text-shadow: 0 0 5px rgba($orange-color, 0.8);
            color: $accient-color;
        }

        &__watermark-image {
            height: 10%;
        }
    }
</style>