<template>
        <div class="img-lightbox">
            <img class="img-lightbox__thumbnail" :src="src" :alt="alt" @click="open()">
            <teleport to="body">
                <div class="img-lightbox__overlay" v-if="active" @click="close()">
                    <i class="lni lni-close img-lightbox__close-icon"></i>
                    <img class="img-lightbox__full-image" :src="src" :alt="alt" @click.stop>
                </div>
            </teleport>
        </div>
</template>

<script>
    export default {
        name: "l-img-lightbox",
        inheritAttrs: false,
        props: {
            src: {
                type: String,
                required: true
            },
            alt: {
                type: String,
                required: true
            }
        },
        data () {
            return {
                active: false
            }
        },
        methods: {
            open() {
                this.active = true;
                this.$gtag.event('open', {img: this.alt});

            },
            close() {
                this.active = false;
                this.$gtag.event('close', {img : this.alt});
            }
        },
        watch: {
            active() { 
                document.body.style.overflow = this.active ? 'hidden' : 'auto';
            }
        }
    }
</script>

<style lang="scss">
    .img-lightbox {
        
        &__thumbnail {
            display: block;
            width: 100%;
            height: auto;
            cursor: pointer;
        }

        &__overlay {
            position: fixed;
            display: block;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.9);
        }
        &__full-image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-height: 90%;
            width: auto;
            max-width: 90%;
            max-height: 90%;
        }

        &__close-icon {
            position: absolute;
            z-index: 5;
            top: 20px;
            right: 20px;
            font-size: 3rem;
            font-weight: 800;
            color: $primary-color;
            cursor: pointer;
        }
    }
</style>