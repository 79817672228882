<template>
    <section class="block s-slider">
        <div class="s-slider__column s-slider__column--title" v-motion-left>
            <div class="s-slider__pretitle">
                <div class="s-slider__pretitle-text">Witaj.</div>
                <c-profile-image class="s-slider__profile-image s-slider__profile-image--pretitle" modifier="small" />
            </div>
            <h2 class="s-slider__title">Nazywam się Mikołaj</h2>
            <div class="s-slider__subtitle">Jestem Fullstack Web Developerem</div>
            <i-button class="s-slider__button" icon="lni lni-linkedin-original" @click="linkTo($event, 'linkedin', 'slider', 'https://www.linkedin.com/in/mikolaj-kawczynski/')">Mój LinkedIn</i-button>
        </div>
        <div class="s-slider__column s-slider__column--profile">
           <c-profile-image class="s-slider__profile-image" v-motion-right/>
        </div>
    </section>
</template>

<script>
    import iButton from '@/components/interface/i-button.vue';
    import cProfileImage from '@/components/c-profile-image.vue';
    import breakpointsMixin from '@/mixins/breakpoints';
    import linkToMixin from '@/mixins/linkTo';

    export default {
        name: "s-slider",
        mixins: [breakpointsMixin, linkToMixin],
        components: {
            iButton,
            cProfileImage
        }
    }
</script>

<style lang="scss">
    .s-slider {
       justify-content: space-between;

       @include brakepoint('sm') {
            display: flex;
       }

       &__column {
            
            &--profile {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1rem;
                margin-left: 2rem;
            }

            @include brakepoint('xs') {
                
                &--profile {
                    display: none;
                }
            }

            @include brakepoint('sm') {
                flex: 1;

                &--title {
                    min-width: 280px;
                }

                &--profile {
                    display: flex;
                    padding-left: 2rem;
                    border-left: 1px solid $gray-dark-color;

                }
            }

            @include brakepoint('md') {
                &--title {
                    min-width: 400px;
                }
            }
        }

        &__pretitle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20vw;
            font-weight: 800;

            @include brakepoint('sm') {
                font-size: 7rem;
            }

            @include brakepoint('md') {
                font-size: 10rem;
            }
        }
        
        &__title {
            margin: 0.5rem 0 1.5rem;
            font-size: 8vw;
            font-weight: 200;

            @include brakepoint('sm') {
                font-size: 3rem;
            }

            @include brakepoint('md') {
                font-size: 4rem;
            }
        }

        &__subtitle {
            margin-bottom: 3rem;
            font-size: 5vw;
            font-weight: 100s;

            @include brakepoint('sm') {
                font-size: 2rem;
            }
        }

        &__button {
            display: none;

            @include brakepoint('xs') {
                display: block;
            }
        }

        &__profile-image {

            max-width: 350px;
            &--pretitle {
                display: none;

                @include brakepoint('xs') {
                    display: block;
                    margin-left: 20px;
                }

                @include brakepoint('sm') {
                    display: none;
                }
            }
        }
    }

</style>