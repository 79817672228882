<template>
    <nav class="hash-menu">
        <ul class="hash-menu__list">
            <li v-for="item in items" :key="item" @click="select(item)" class="hash-menu__item" :class="{'hash-menu__item--active': item == modelValue}">#{{ item }}</li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "i-hash-menu",
        props: {
            modelValue: {
                type: String,
                required: true,
            },
            items: {
                type: Array,
                required: true,
            }
        },
        methods: {
            select(item) {
                this.$emit('update:modelValue', item);
                this.$gtag.event('nav-use', {location: 'hash-menu', action: item});
            }
        }
    }
</script>

<style lang="scss">
    .hash-menu {
        &__list {
            max-width: 30rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            margin: 0;
            padding: 0;
            list-style: none;
            flex-wrap: wrap;

            @include brakepoint('sm') {
                display: flex;
                max-width: unset;

            }
        }

        &__item {
            display: block;
            padding: 1.5rem 2rem;
            font-size: 1.2rem;
            cursor: pointer;
            user-select: none;
            text-align: center;
            &:hover,
            &--active {
                box-shadow: $box-shadow;
            }

            @include brakepoint('md') {
                font-size: 1.3rem;
            }
        }
    }
</style>