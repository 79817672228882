<template>
    <section class="block s-skills">
        <c-section-header class="s-skills__header" icon="lni lni-bulb" bgtext="Umiejętności">Moje umiejętności</c-section-header>
        <p class="s-skills__paragraph">Podczas realizacji wielu projektów nabyłem szereg praktycznych umiejętności. Posiadam bardzo szerokie spektrum umiejętności jednak jak nie trudno się domyślić występują istotne różnice w poziomie ich znajomości. Jako programista uważam że, poziom procentowy jest tu najbardziej obrazowy.</p>
        <u-skills-stats class="s-skills__stats"/>
        <div class=" s-skills__columns">
            <div class="s-skills__column s-skills__column--text" v-motion-left>
                <h2 class="subheader s-skills__subheader">Umiejętności miękkie</h2>
                <p class="s-skills__paragraph">Podczas prowadzenia projektów i spotkań z klientami nabyłem nie tylko umiejętności techniczne, ale także cenne doświadczenie współpracy w zespole i z klientem. Co przełożyło się na konkretne umiejętności.</p>
            </div>
            <div class="s-skills__column s-skills__column--charts" v-motion-right>
                <g-skill-chart class="s-skills__chart" :value="100" type="blue">Kontaktowy</g-skill-chart>
                <g-skill-chart class="s-skills__chart" :value="75" type="green">Kreatywny</g-skill-chart>
                <g-skill-chart class="s-skills__chart" :value="75" type="red">Zespołowy</g-skill-chart>
            </div>
        </div>
    </section>
</template>

<script>
    import cSectionHeader from '@/components/c-section-header.vue';
    import uSkillsStats from '@/components/unique/u-skills-stats.vue';
    import gSkillChart from '@/components/graphs/g-skill-chart.vue';

    export default {
        name: "s-skills",
        components: {
            cSectionHeader,
            uSkillsStats,
            gSkillChart
        }
    }
</script>

<style lang="scss">
    .s-skills {
        padding-top: 7rem;
        
        &__columns {
            @include brakepoint('md') {
                display: flex;
            }
        }

        &__column {
            flex: 1;
            &--text {
                flex: 2;
                padding-right: 10%;
            }

            &--charts {
                flex: 3;
                display: grid;
                align-items: center;
                justify-content: center;
                margin: auto;
                gap: 1rem;
                max-width: 500px;
                margin-top: 2rem;
                grid-template-columns: repeat(3, 1fr);

                @include brakepoint('md') {
                    display: flex;
                    max-width: unset;
                    justify-content: space-between;
                    margin-top: 0;
                }
                
            }
        }

        &__chart {
            max-width: 150px;
        }
    }
</style>