import '@/styles/global.scss';
import itemsColorsMixin from '@/mixins/items-colors'
import { createApp } from 'vue';
import App from './App.vue';
import store from '@/api/store';
import motionsDirectives from './derectives/motions';
import { MotionPlugin } from '@vueuse/motion'
import VueGtag from "vue-gtag";
import Cookies from 'js-cookie';

const debug = Cookies.get('debug') === 'true' || process.env.NODE_ENV !== 'production';
const gtag = debug ? process.env.VUE_APP_DEBUG_GTAG : process.env.VUE_APP_GTAG
window.debug = function(debug) {
    Object.keys(Cookies.get()).forEach(key => Cookies.remove(key));
    if(debug)
        Cookies.set('debug', 'true', { expires: 365 });

    window.location.reload();
    return debug;
  };
  

const app = createApp(App) //eslint-disable-line no-unused-vars
                .use(store)
                .use(MotionPlugin, motionsDirectives)
                .use(VueGtag, {config: { id: gtag}, debug: debug})
                .mixin(itemsColorsMixin)
                .mount(document.body); 