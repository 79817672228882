<template>
    <header class="section-header">
        <div class="section-header__bg-text">{{ bgtext }}</div>
        <div class="section-header__title">
            <div :class="icon" class="section-header__icon"></div>
            <h2 class="section-header__header"><slot></slot></h2>
        </div>
    </header>
</template>

<script>
    export default {
        name: "c-section-header",
        props: {
            bgtext: {
                type: String
            },
            icon: {
                type: String,
                required: true
            }
        }
    }
</script>

<style lang="scss">
    .section-header {
        position: relative;
        margin-bottom: 3rem;
        
        &__title {
            position: relative;
            z-index: 1;
            align-items: center;
            display: flex;
        }

        &__icon {
            margin-right: 1.5rem;
            color: $primary-color;
            font-size: 3.5rem;
        }

        &__header {
            margin: 0;
            font-size: 2rem;
            font-weight: 500;
            text-transform: capitalize;

            @include brakepoint('xs') {
                font-size: 3rem;
            }
        }

        &__bg-text {
            position: absolute;
            top: -2rem;
            font-size: 7rem;
            color: $gray-light-color;
            font-weight: 800;
            text-transform: uppercase;
            user-select: none;
            white-space: nowrap;
        }
    }
</style>