<template>
    <nav class="main-menu">
        <ul class="main-menu__list">
            <li class="main-menu__item" v-for="section in sections" :key="section.slug">
                <a  class="main-menu__link" :class="{'main-menu__link--active': activeSection === section.slug}" :href="`#${section.slug}`" @click.prevent="scrollToSection(section)">
                    <i class="lni main-menu__icon" :class="section.icon"></i>
                    <span class="main-menu__label">{{ section.name }}</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: "i-main-menu",
        data() {
            return {
                sections: [
                    {
                        name: "Nagłówek",
                        slug: "naglowek",
                        icon: "lni-home",
                        fixed: 0
                    }, {
                        name: "O mnie",
                        slug: "o-mnie",
                        icon: "lni-user"
                    }, {
                        name: "Umiejętności",
                        slug: "umiejetnosci",
                        icon: "lni-bulb"
                    }, {
                        name: "Doświadczenie",
                        slug: "doswiadczenie",
                        icon: "lni-briefcase"
                    }
                ],
                
                observer: null
            }
        },
        props: {
            activeSection: String,
            topMargin: {
                type: Number,
                default: 0
            }
        },
        methods: {
            scrollToSection(section) {
                let topOffset = null;
                if(section.fixed === undefined) {
                    const target = document.getElementById(section.slug);
                    if (section)
                        topOffset = target.getBoundingClientRect().top + window.scrollY - this.topMargin;
                } else
                    topOffset = section.fixed;

                if (topOffset !== null) {
                    window.scrollTo({
                        top: topOffset,
                        behavior: 'smooth'
                    });
                }
                this.$gtag.event('nav-use', {location: 'main-menu', action: section.slug});

            }
        }
    }
</script>

<style lang="scss">
    .main-menu {
        --link-padding: 0;
        display: flex;
        position: fixed;
        top: 0;
        right: 1rem;
        box-sizing: border-box;
        height: 100vh;
        z-index: 2;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;

        @include brakepoint('lg') {
            --link-padding: 2rem;
            position: sticky;
            top: calc(var(--link-padding) * -0.5);
            height: 100px;
            margin: 30px;
            padding: 0;
            background-color: $white-color;
            box-shadow: $box-shadow;
        }

        &__list {
            display: flex;
            justify-content: center;
            flex-direction: column;
            list-style: none;
            margin: 0;
            padding: 0;

            @include brakepoint('lg') {
                justify-content: flex-start;
                flex-direction: row;
            }
        }

        &__link {
            $size: 50px;
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 1.2rem;
            color: $font-color;
            text-decoration: none;

            &--active {
                $overflow: 0.2;
                position: relative;
                color: $white-color;
                background-color: $primary-color;

                &:hover {
                    color: $white-color;
                }
            }

            &:hover:not(#{& + "--active"}) {
                color: $font-color;
                background-color: $gray-light-color;
            }

            @include brakepoint('lg') {
                box-sizing: content-box;
                height: 100%;
                padding: var(--link-padding);
                top: calc(var(--link-padding) * -1);
                position: relative;
                border-radius: 0;
            }
        }

        &__icon {
            font-weight: 500;;
            font-size: 2rem;

            @include brakepoint('lg') {
                display: none;
            }
        }

        &__label {
            display: none;
            text-transform: capitalize;

            @include brakepoint('lg') {
                display: block;
            }
        }
    }
</style>