const transition = {
    duration: 800,
    delay: 100,

};
export default {
    excludePresets: true,
    directives: {
        'fade': {
            initial: { opacity: 0 },
            visible: { opacity: 1, transition: transition },
            leave: { opacity: 0, transition: transition }
        },
        'right': {
            initial: { opacity: 0, transform: 'translateX(100px)' },
            visible: { opacity: 1, transform: 'translateX(0px)', transition: transition },
            leave: { opacity: 0, transform: 'translateX(100px)', transition: transition }
        },
        'left': {
            initial: { opacity: 0, transform: 'translateX(-100px)' },
            visible: { opacity: 1, transform: 'translateX(0px)', transition: transition },
            leave: { opacity: 0, transform: 'translateX(-100px)', transition: transition }
        },
        'up': {
            initial: { opacity: 0, transform: 'translateY(100px)' },
            visible: { opacity: 1, transform: 'translateY(0px)', transition: transition },
            leave: { opacity: 0, transform: 'translateY(100px)', transition: transition }
        },
        'down': {
            initial: { opacity: 0, transform: 'translateY(-100px)' },
            visible: { opacity: 1, transform: 'translateY(0px)', transition: transition },
            leave: { opacity: 0, transform: 'translateY(-100px)', transition: transition }
        },
        'left-up' : {
            initial: { opacity: 0, transform: 'translate(-100px, 100px)' },
            visible: { opacity: 1, transform: 'translate(0px, 0px)', transition: transition },
            leave: { opacity: 0, transform: 'translate(-100px, 100px)', transition: transition }
        },
        'right-up' : {
            initial: { opacity: 0, transform: 'translate(100px, 100px)' },
            visible: { opacity: 1, transform: 'translate(0px, 0px)', transition: transition },
            leave: { opacity: 0, transform: 'translate(100px, 100px)', transition: transition }
        },
        'left-down' : {
            initial: { opacity: 0, transform: 'translate(-100px, -100px)' },
            visible: { opacity: 1, transform: 'translate(0px, 0px)', transition: transition },
            leave: { opacity: 0, transform: 'translate(-100px, -100px)', transition: transition }
        },
        'right-down' : {
            initial: { opacity: 0, transform: 'translate(100px, -100px)' },
            visible: { opacity: 1, transform: 'translate(0px, 0px)', transition: transition },
            leave: { opacity: 0, transform: 'translate(100px, -100px)', transition: transition }
        }
    }
};