import { Store } from 'vuex';

import jobs from '@/data/jobs.json'
import personal from '@/data/personal.json'
import skills from '@/data/skills.json'
import LocalStorygeController from '@/api/LocalStorygeController';

const localStorygeController = new LocalStorygeController({
    authenticated: false,
    authenticatedExpire: null,
    jobs: jobs,
    personal: personal,
    skills: skills,
    form: {
        ready: false,
        name: '',
        email: '',
        title: '',
        message: ''
    }
});

export default new Store({
    state () {
        return localStorygeController.get();
    },
    mutations: {
        reset(state) {
            Object.assign(state, localStorygeController.init());
        },
        setAuthenticated(state, authenticated) {
            state.authenticated = authenticated;
        },
    },
    getters: {
        authenticated: state => state.authenticated,
        jobs: state => state.jobs,
        personal: state => state.personal,
        skills: state => state.skills
    },
    actions: {
        auth({ commit }, sucess) {
            commit('setAuthenticated', sucess);
        }
    }
});