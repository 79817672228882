<template>
    <nav :class="blockModifiersClasses">
        <svg :class="elementModifiersClasses('button')" @click="$emit('click')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 80" preserveAspectRatio="xMidYMid meet">
            <path :class="elementModifiersClasses('background')" d="M0,0v80c2.8-14.2,11.5-23.5,20.9-29.5s8.5-15.7,0-21.1S2.8,14.2,0,0Z"/>
            <path :class="elementModifiersClasses('icon')" d="M7,48.5c-.1,0-.3,0-.4-.2-.2-.2-.2-.6,0-.8l7.7-8c.2-.2.2-.5,0-.6l-7.7-8c-.2-.2-.2-.6,0-.8.2-.2.6-.2.8,0l7.7,8c.6.6.6,1.6,0,2.2l-7.7,8c-.1.1-.3.2-.4.2Z"/>
        </svg>
    </nav>
</template>

<script>
    import modifiers from '@/mixins/modifiers';

    export default {
        name: "i-expand",
        mixins: [modifiers],
        emits: ['click'],
        props: {
            active: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            localModifiers() {
                return {
                    'active': this.active
                };
            }
        }

    }
</script>

<style lang="scss">
    .expand {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 50px;
        height: 100vh;

        &__button {
            max-height: 10rem;
            cursor: pointer;

            --icon-color: var(--font-color);
            --background-color: var(--white-color);

            &:hover,
            &:focus {
                --background-color: var(--gray-light-color);
            }

            &:active {
            --icon-color: var(--white-color);
            --background-color: var(--primary-color);
            }
            
        }

        &__background {
            overflow: hidden;
            fill: var(--background-color);
            @include animation(fill);
        }

        &__icon {
            fill: transparent;
            fill: var(--font-color);
            transform-origin: 35% center;
            @include animation(fill, transform);

            &--active {
                transform: rotateY(180deg);
            }
        }
    }
</style>