export default {
    data: () => {
        return {
            itemsColors: ['blue', 'navy', 'green', 'orange', 'red', 'purple', 'orange']
        }
    },
    methods: {
        getItemColor(index) {
            return this.itemsColors[index % this.itemsColors.length]
        }
    }
}