<template>
    <teleport to="body">
        <div class="text-lightbox" v-if="active" @click="close()">
            <i class="lni lni-close text-lightbox__close-icon"></i>
            <div class="block text-lightbox__content" @click.stop>
                <slot></slot>
            </div>
        </div>
    </teleport>
</template>

<script>
    export default {
        name: "l-text-lightbox",
        inheritAttrs: false,
        emits: ['close'],
        data: () => {
            return {
                active: false
            }
        },
        watch: {
            active() { 
                document.body.style.overflow = this.active ? 'hidden' : 'auto';
            }
        },
        methods: {
            close() {
                this.$emit('close');
                this.active = false;
            },
            open() {
                this.active = true;
            }
        }
    }
</script>

<style lang="scss">
    .text-lightbox {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.9);
        
        @include brakepoint("sm") {
            display: flex;
        }

        &__close-icon {
            position: fixed;
            z-index: 5;
            top: 2rem;
            right: 2rem;
            font-size: 3rem;
            font-weight: 800;
            color: $primary-color;
            cursor: pointer;
        }

        &__content {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            margin: 0;
            box-shadow: none;
            overflow-y: auto;

            @include brakepoint("sm") {
                width: 80vw;
                height: auto;
                max-width: 800px;
                max-height: 80vh;
            }
        }
    }
</style>