<template>
    <div class="skill-block" :class="'skill-block--' + color" @click="openLightbox()" ref="skillBlock">
        <img :src="icon" alt="icon" class="skill-block__icon">
        <h3 class="skill-block__title" :style="{'font-size': this.skillBlockSize * 0.01 + 'em'}">{{ title }}</h3>
        <l-text-lightbox ref="lightBox" @click.stop @close="this.$gtag.event('close', {'skill-block': this.title})">
            <h3 class="subheader skill-block__title skill-block__title--lightbox"> {{ title }} </h3>
            <p class="skill-block__description">
                <slot></slot>
            </p>
        </l-text-lightbox>
    </div>
</template>

<script>
    import { useElementSize } from '@vueuse/core';
    import { ref } from 'vue'
    import lTextLightbox from '@/components/lightboxes/l-text-lightbox.vue';

    export default {
        name: "c-skill-block",
        props: {
            icon: {
                type: String,
                Required: true
            },
            title: {
                type: String,
                default: ""
            },
            color: {
                type: String,
                default: "blue"
            }
        },
        methods: {
            openLightbox() {
                this.$refs.lightBox.open();
                this.$gtag.event('open', 'skill-block', this.title);
            }
        },
        setup() {
            const skillBlock = ref(null);
            return {
                skillBlock,
                skillBlockSize: useElementSize(skillBlock).width
            }
        },
        components: {
           lTextLightbox
        }
    }
</script>

<style lang="scss">
    .skill-block {
        display: grid;
        aspect-ratio: 1 / 1;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 30%;
        overflow: hidden;
        gap: auto;
        padding: 10%;
        border-bottom: 2px solid;
        box-sizing: border-box;
        box-shadow: $box-shadow;
        font-size: 1.3rem;
        background-color: $white-color;
        cursor: pointer;
        @include colors-modyfaiers('border-color');

        @include brakepoint('md') {
            font-size: 1rem;
        }
        &__icon {
            width: 40%;
            min-width: 50px;
        }

        &__title {
            font-size: 1.5em;

            &--lightbox {
                text-transform: capitalize;
                font-size: 2rem;
            }
        }

        &__description {
            font-size: 1.3rem;
        }
    }
</style>